<template>
  <div style="margin: auto; padding: 50rem 36rem; width: 98%">
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="基础信息"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <div class="base">
        <el-row>
          <el-col class="key" :lg="4">班主任姓名</el-col>
          <el-col class="val" :lg="20">{{ details.basics.teacher_name }}</el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">工作完成情况</el-col>
          <el-col class="val" :lg="20" :style="{ color: type === 1 ? 'green' : 'red' }"
            >{{ type === 0 ? '未完成工作' : '已完成工作' }}
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">行政班班级</el-col>
          <el-col class="val" :lg="20">{{ details.basics.class_name }}</el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">统计日期</el-col>
          <el-col class="val" :lg="20">{{ details.basics.work_date }}</el-col>
        </el-row>
      </div>
    </t-card>
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="查早操"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <empty class="base" v-if="!details.early_reading"></empty>
      <div v-else class="base">
        <el-row>
          <el-col class="key" :lg="4">早操纪律评分</el-col>
          <el-col class="val" :lg="20">{{ details.early_reading.work_score }}</el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">人员是否到齐</el-col>
          <el-col class="val" :lg="20">{{ details.early_reading.stu_complete }}</el-col>
        </el-row>
        <el-row v-if="details.early_reading.image">
          <el-col class="key" :lg="4">现场记录</el-col>
          <el-col class="val" :lg="20">
            <div class="demo-image">
              <div class="block">
                <el-image
                  style="width: 100rem; height: 100rem"
                  :src="details.early_reading.image"
                  fit="contain"
                  :preview-src-list="
                    details.early_reading.image ? details.early_reading.image.split(',') : []
                  "></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">备注</el-col>
          <el-col class="val" :lg="20">{{ details.early_reading.work_remarks }}</el-col>
        </el-row>
      </div>
    </t-card>
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="查卫生"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <empty class="base" v-if="!details.check_health"></empty>
      <div v-else class="base">
        <!--      <el-row>-->
        <!--        <el-col class="key" :lg="4">是否完成卫生检查工作</el-col>-->
        <!--        <el-col class="val" :lg="20">{{ details.check_health.stu_complete }}</el-col>-->
        <!--      </el-row>-->
        <el-row>
          <el-col class="key" :lg="4">黑板卫生</el-col>
          <el-col class="val" :lg="20">{{ details.check_health.board }}</el-col>
        </el-row>
        <el-row v-if="details.check_health.board_image">
          <el-col class="key" :lg="4">现场记录</el-col>
          <el-col class="val" :lg="20">
            <div class="demo-image">
              <div class="block">
                <el-image
                  style="width: 100rem; height: 100rem"
                  :src="details.check_health.board_image"
                  fit="contain"
                  :preview-src-list="
                    details.check_health.board_image ? details.check_health.board_image.split(',') : []
                  "></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">地面卫生</el-col>
          <el-col class="val" :lg="20">{{ details.check_health.ground }}</el-col>
        </el-row>
        <el-row v-if="details.check_health.ground_image">
          <el-col class="key" :lg="4">现场记录</el-col>
          <el-col class="val" :lg="20">
            <div class="demo-image">
              <div class="block">
                <el-image
                  style="width: 100rem; height: 100rem"
                  :src="details.check_health.ground_image"
                  fit="contain"
                  :preview-src-list="
                    details.check_health.ground_image ? details.check_health.ground_image.split(',') : []
                  "></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">桌椅是否摆放整齐</el-col>
          <el-col class="val" :lg="20">{{ details.check_health.tables }}</el-col>
        </el-row>
        <el-row v-if="details.check_health.tables_image">
          <el-col class="key" :lg="4">现场记录</el-col>
          <el-col class="val" :lg="20">
            <div class="demo-image">
              <div class="block">
                <el-image
                  style="width: 100rem; height: 100rem"
                  :src="details.check_health.tables_image"
                  fit="contain"
                  :preview-src-list="
                    details.check_health.tables_image ? details.check_health.tables_image.split(',') : []
                  "></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">备注</el-col>
          <el-col class="val" :lg="20">{{ details.check_health.work_remarks }}</el-col>
        </el-row>
      </div>
    </t-card>
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="查晚自习"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <empty class="base" v-if="!details.self_study"></empty>
      <div v-else class="base">
        <el-row>
          <el-col class="key" :lg="4">晚自习纪律评分</el-col>
          <el-col class="val" :lg="20">{{ details.self_study.work_score }}</el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">人员是否到齐</el-col>
          <el-col class="val" :lg="20">{{ details.self_study.stu_complete }}</el-col>
        </el-row>
        <el-row v-if="details.self_study.image">
          <el-col class="key" :lg="4">现场记录</el-col>
          <el-col class="val" :lg="20">
            <div class="demo-image">
              <div class="block">
                <el-image
                  style="width: 100rem; height: 100rem"
                  :src="details.self_study.image"
                  fit="contain"
                  :preview-src-list="details.self_study.image ? details.self_study.image.split(',') : []"></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="key" :lg="4">备注</el-col>
          <el-col class="val" :lg="20">{{ details.self_study.work_remarks }}</el-col>
        </el-row>
      </div>
    </t-card>
    <div style="width: 100%; height: 48rem"></div>
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="学习习惯监督"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <empty
        class="base"
        v-if="!details.study_habits || (Array.isArray(details.study_habits) && !details.study_habits.length)"></empty>
      <div v-else class="base">
        <el-row class="habit" v-for="item in details.study_habits">
          <el-col :lg="2">学生姓名</el-col>
          <el-col :lg="4">{{ item.student_name }}</el-col>
          <el-col :lg="2">本次监督内容</el-col>
          <el-col :lg="16">{{ item.supervise_value }}</el-col>
          <el-col :lg="2">备注</el-col>
          <el-col :lg="22">{{ item.work_remarks }}</el-col>
        </el-row>
      </div>
    </t-card>
    <t-card
      style="border-top: 1rem solid #e8edff"
      title="工作反思"
      :title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }">
      <empty class="base" v-if="!details.work_reflection"></empty>
      <div v-else class="base">
        {{ details.work_reflection.work_reflect_content }}
      </div>
    </t-card>
    <div class="footer-button">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import Empty from '../components/Empty'

export default {
	_config:{"route":{"path":"show","meta":{"title":"详情"}}},
  components: {
    Empty
  },
  data() {
    return {
      id: null,
      type: '',
      rate: ['未完成', '非常差', '比较差', '一般', '比较好', '非常好'],
      details: {
        basics: {
          teacher_name: '高探彪',
          class_name: '高2022届28班',
          work_date: '2022.06.17'
        },
        early_reading: {
          work_score: 4,
          stu_complete: 1,
          work_remarks: 'ccccc',
          image: ''
        },
        check_health: {
          board: '',
          board_image: '',
          ground: '',
          ground_image: '',
          tables: '',
          tables_image: ''
        },
        self_study: [],
        study_habits: [],
        work_reflection: []
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.type = parseInt(this.$route.query.type)
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2.get('/api/work/teacher-class/journal-details?id=' + this.id).then((res) => {
        let data = res.data
        if (data.status === 0) {
          this.details = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.msg,
            showClose: true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base {
  line-height: 50rem;
  padding: 20rem 0;
  font-size: 14rem;

  .key {
    color: #999;
    font-weight: 400;
  }

  .habit {
    background-color: #efefef;
    margin-bottom: 20rem;
    padding: 0 20rem;
  }
}
</style>
